import { get, isEmpty, reduce } from 'lodash-es';
import moment from 'moment';
import { createSelector, defaultMemoize } from 'reselect';
import { parseShareLink } from '../../../components/formik/ShareLinks/ShareLinks';

import LLCDict from '../../../utils/LLCDict';

const defaultAddress = {
  line_one: '',
  line_two: '',
  city: '',
  state: '',
  zip: '',
  country: 'United States of America',
};

export const isFetchingWithHooks = defaultMemoize(state =>
  get(state, 'Transaction.isFetching', false),
);

export const isFetching = defaultMemoize(state => get(state, 'isFetching', false));

const getTransactionById = defaultMemoize((state, id) =>
  get(state, `transactions.byIds.${id}`, {}),
);

const TransactionById = defaultMemoize((state, id) =>
  get(state, `Transaction.transactions.byIds.${id}`, {}),
);

export const getQuestionnaireByTransactionId = defaultMemoize((state, id) =>
  get(state, `questionnaires.byIds.${id}.questionnaire`, {}),
);

export const getQuestionnaireSnapshotByTransactionId = defaultMemoize((state, id) =>
  get(state, `questionnaires.byIds.${id}.questionnaire.finalize_snapshot[0]`, {}),
);

const WorkflowCounselQuestions = defaultMemoize((state, id) =>
  get(state, `Transaction.questionnaires.byIds.${id}.counsel`, []),
);

const WorkflowCounselOptions = defaultMemoize((state, id) =>
  get(state, `Transaction.questionnaires.byIds.${id}.counsel_options`, {}),
);

const WorkflowProjectCounsels = defaultMemoize((state, id, projectId) =>
  get(state, `Project.accountProjects.byIds.${projectId}.counsel`, []),
);

export const IsWorkflowCounselShowing = defaultMemoize(
  (state, id) =>
    get(state, `Transaction.questionnaires.byIds.${id}.counsel`, []).length > 0,
);

export const WorkflowCounselForQuestionnaire = createSelector(
  WorkflowCounselQuestions,
  WorkflowCounselOptions,
  WorkflowProjectCounsels,
  (counselQuestions, counselOptions, counsels) =>
    counselQuestions.reduce((dict, e) => {
      let counselIndex = counsels.findIndex(
        counsel =>
          counsel.counsel_type === e.counsel_type &&
          (!isEmpty(counsel.user) || !isEmpty(counsel.partner)),
      );
      let formikValue = '';
      let options = [];
      const pastOptions = get(counselOptions, `past[${e.counsel_type}]`, []).map(e => ({
        ...e,
        isPastOption: true,
      }));

      if (!isEmpty(counselOptions)) {
        options = [...pastOptions, ...counselOptions.allowed];
      }

      options = options.reduce((updatedOptions, option) => {
        if (
          updatedOptions.findIndex(
            val => val.user_id === option.user_id && val.partner_id === option.partner_id,
          ) !== -1
        ) {
          return updatedOptions;
        }
        if (
          (!!e.allow_partner && !!e.allow_user) ||
          (!!e.allow_partner && option.partner_id) ||
          (!!e.allow_user && option.user_id)
        ) {
          let label =
            option.name ||
            (!isEmpty(option.user) &&
              !isEmpty(option.partner) &&
              `${option.user.full_name} from ${option.partner.name}`) ||
            (!isEmpty(option.user) && option.user.full_name) ||
            (!isEmpty(option.partner) && option.partner.name);

          if (e.isPastOption && !e.preferred_partner_id) {
            label += ' (Priorly Assigned Role)';
          }

          updatedOptions.push({
            ...option,
            counsel_type: e.counsel_type,
            label,
            value:
              (option.partner_id &&
                option.user_id &&
                `${option.user_id}|${option.partner_id}`) ||
              option.partner_id ||
              option.user_id,
          });
        }
        return updatedOptions;
      }, []);

      if (counselIndex !== -1) {
        const counsel = counsels[counselIndex];
        const matchingCounselIndex = options.findIndex(
          option =>
            (counsel.user_id &&
              counsel.partner_id &&
              counsel.user_id === option.user_id &&
              counsel.partner_id === option.partner_id) ||
            (counsel.user_id &&
              !counsel.partner_id &&
              counsel.user_id === option.user_id) ||
            (counsel.partner_id &&
              !counsel.user_id &&
              counsel.partner_id === option.partner_id),
        );
        if (matchingCounselIndex !== -1) {
          formikValue = {
            ...options[matchingCounselIndex],
            id: counsel.id,
          };
          options[matchingCounselIndex] = formikValue;
        } else {
          let label =
            counsel.name ||
            (!isEmpty(counsel.user) &&
              !isEmpty(counsel.partner) &&
              `${counsel.user.full_name} from ${counsel.partner.name}`) ||
            (!isEmpty(counsel.user) && counsel.user.full_name) ||
            (!isEmpty(counsel.partner) && counsel.partner.name);

          formikValue = {
            ...counsel,
            label,
            value:
              (counsel.partner_id &&
                counsel.user_id &&
                `${counsel.user_id}|${counsel.partner_id}`) ||
              counsel.user_id ||
              counsel.partner_id,
          };
          options.shift(formikValue);
        }
      }

      dict.push({
        ...e,
        formikValue,
        // isNotAssigned,
        label: `Assign ${e.counsel_type} to this workflow`,
        options,
      });
      return dict;
    }, []),
);

export const DocumentError = defaultMemoize(state =>
  get(state, 'Transaction.documentError', ''),
);

export const getConnectedOutsideForms = createSelector(
  getTransactionById,
  ({ tasks = [] }) => {
    let isEmpty = true;
    let openFormTemplates = [];
    let openForms = [];
    let sharedForms = [];
    tasks.forEach(e => {
      if ((e?.outside_forms || []).length > 0) {
        isEmpty = false;
        e.outside_forms.forEach(form => {
          if (form.metadata?.type === 'persistent') {
            openFormTemplates.push(form);
          } else if (form.metadata?.type === 'persistent_instance') {
            openForms.push(form);
          } else {
            sharedForms.push(form);
          }
        });
      }
    });
    return {
      isEmpty,
      openFormTemplates,
      openForms,
      sharedForms,
    };
  },
);

export const getNonOpenForms = createSelector(getTransactionById, ({ tasks = [] }) => {
  let sharedForms = [];
  tasks.forEach(e => {
    if ((e?.outside_forms || []).length > 0) {
      e.outside_forms.forEach(form => {
        if (
          form.metadata?.type !== 'persistent' &&
          form.metadata?.type === 'persistent_instance'
        ) {
          sharedForms.push(form);
        }
      });
    }
  });
  return sharedForms;
});

export const getSharedForms = createSelector(getTransactionById, ({ tasks = [] }) =>
  tasks.reduce((dict, e) => {
    if (get(e, 'outside_forms', []).length > 0) {
      dict = [...dict, ...e.outside_forms];
    }
    return dict;
  }, []),
);

export const isDocumentTransaction = createSelector(
  getTransactionById,
  ({ tasks = [] }) =>
    tasks.findIndex(
      e =>
        e.template.type === 'Document' &&
        !e.bypassed &&
        !get(e.template, `task_event.modes[${e.mode}].skip_document`, false),
    ) !== -1,
);

export const IsDocumentTransaction = createSelector(
  TransactionById,
  ({ tasks = [] }) =>
    tasks.findIndex(
      e =>
        e.template.type === 'Document' &&
        !e.bypassed &&
        !get(e.template, `task_event.modes[${e.mode}].skip_document`, false),
    ) !== -1,
);

const getTransactionValidators = createSelector(getTransactionById, transaction =>
  get(transaction, 'template.validators', []),
);

const getQuestions = defaultMemoize((state, id) =>
  get(state, `questionnaires.byIds.${id}.questionnaire.questions`, []),
);

export const CustomDocuments = defaultMemoize((state, id) =>
  get(state, `customDocuments.byIds[${id}]`, []),
);

export const MyTemplatesWithHooks = defaultMemoize(state =>
  get(state, 'Transaction.myTemplates', []),
);

const getTransactionsByModuleId = defaultMemoize((state, id) =>
  get(state, `transactions.byModule.${id}`, []).sort((a, b) =>
    moment(b.created_at).diff(moment(a.created_at)),
  ),
);

const getDocumentsByTransaction = defaultMemoize((state, id) =>
  get(state, `documents.byTransaction.${id}`, []),
);

const getOrderedDocumentsByTransaction = createSelector(
  getDocumentsByTransaction,
  documents =>
    documents.reduce((dict, e) => {
      e.redline_data.redlining_revisions.sort((a, b) =>
        moment(a.create_date).diff(moment(b.create_date)),
      );
      dict.push(e);
      return dict;
    }, []),
);

export const TransactionWithDocuments = createSelector(
  getDocumentsByTransaction,
  getTransactionById,
  (documents, transaction) => {
    let isRedlining = false;
    const updatedTasks = get(transaction, 'tasks', []).reduce((dict, e) => {
      const docIndex = documents.findIndex(doc => doc.account_task_id === e.id);
      if (docIndex !== -1) {
        if (get(documents, `[${docIndex}].panda_data.redlining.status`, 0)) {
          isRedlining = true;
        }
        dict.push({ ...e, document: documents[docIndex] });
      } else {
        dict.push(e);
      }
      return dict;
    }, []);
    return { ...transaction, tasks: updatedTasks, isRedlining };
  },
);

const getTransactionsForModuleView = createSelector(
  getTransactionsByModuleId,
  transactions =>
    transactions.reduce(
      (dict, e, index) => {
        if (e.state === 'Completed') {
          dict.completedTransactions.push(e);
        } else {
          dict.incompleteTransactions.push(e);
        }
        return dict;
      },
      { completedTransactions: [], incompleteTransactions: [] },
    ),
);

const getFaqsFromTransaction = createSelector(getTransactionById, transaction => {
  const faqs = get(transaction, 'tasks', []).reduce((dict, e, index) => {
    (e.template?.faqs || []).forEach(faq => dict.push(faq));
    return dict;
  }, []);
  return faqs;
});

const getQuestionsOrderedByResource = createSelector(getQuestions, questions => {
  const orderedQuestions = reduce(
    questions,
    (dict, e, ind) => {
      if (isEmpty(dict[e.resource_variable_name])) {
        dict[e.resource_variable_name] = {
          isCompany: e.resource_variable_name === 'Company',
          name: e.resource_variable_name,
          custom_label: e.resource_custom_label || e.resource_variable_name,
          multi_entry: e.multi_entry,
          feature_type: 'resource_group',
          resource_type_id: e.feature_type.resource_type_id,
          question_order: e.question_order,
          innerQuestions: [e],
        };
      } else {
        dict[e.resource_variable_name].innerQuestions.push(e);
      }
      dict[e.resource_variable_name].innerQuestions.sort(
        (a, b) => a.question_order - b.question_order,
      );
      return dict;
    },
    {},
  );
  return orderedQuestions;
});

const getResources = defaultMemoize((state, id) =>
  get(state, `questionnaires.byIds.${id}.resources`, {}),
);

const getResourceOptions = createSelector(getResources, resources => ({
  ...reduce(
    resources,
    (dict, e, key) => {
      dict[key] = [
        ...e.map(resource => ({
          value: resource.name,
          label: resource.name,
          is_draft: resource.is_draft,
          resourceId: resource.id,
          resourceType: resource.resource_type,
          finalized: !!resource.finalized,
        })),
      ];
      return dict;
    },
    {},
  ),
}));

const updatePrereqQuestions = (dict, resourceType, resourceTypeIndex) => {
  let resourceTypeResourceArr = resourceType.resourceArr.reduce(
    (resourceArr, resource) => {
      let innerQuestions = resource.innerQuestions.reduce(
        (innerQs, innerQ, questionIndex) => {
          if (innerQ.required_question_id) {
            const requiredQuestionIndex = innerQs.findIndex(
              e => e.id === innerQ.required_question_id,
            );
            const actualVal = get(innerQs, `[${requiredQuestionIndex}].value`, '');
            if (
              (typeof actualVal === 'string' &&
                actualVal !== innerQ.required_question_value) ||
              (typeof actualVal === 'number' &&
                actualVal != innerQ.required_question_value) || // eslint-disable-line eqeqeq
              (typeof actualVal === 'boolean' &&
                innerQ.required_question_value != actualVal) || // eslint-disable-line eqeqeq
              (actualVal.constructor === Array &&
                !actualVal.includes(innerQ.required_question_value))
            ) {
              innerQs[questionIndex] = {
                ...innerQs[questionIndex],
                isHidden: true,
              };
            } else {
              innerQs[questionIndex] = {
                ...innerQs[questionIndex],
                isHidden: false,
              };
            }
          }
          if (innerQ.feature_type.feature_type === 'attorney_lane') {
            innerQ.feature_type.options = innerQ.feature_type.lanes.map(
              ({ id, title }) => ({
                value: id,
                label: title,
              }),
            );
            innerQs[questionIndex] = {
              ...innerQ,
            };
          }
          if (innerQ.feature_type.feature_type === 'attorney') {
            const attorneyLaneIndex = innerQs.findIndex(
              e => e.feature_type.feature_type === 'attorney_lane',
            );
            if (attorneyLaneIndex !== -1 && innerQs[attorneyLaneIndex].value) {
              const updatedOptions = get(
                innerQ,
                `feature_type.attorneys_by_lane[${innerQs[attorneyLaneIndex].value}]`,
                [],
              ).map(e => ({
                label: `${e.first_name} ${e.last_name}`,
                value: e.id,
              }));
              innerQ.feature_type.options = updatedOptions;
              innerQ.isHidden = false;
              if (
                innerQ.value &&
                updatedOptions.findIndex(e => e.value == innerQ.value) === -1 // eslint-disable-line eqeqeq
              ) {
                innerQ.value = '';
              }
            } else {
              innerQ.isHidden = true;
            }
            innerQs[questionIndex] = {
              ...innerQ,
            };
          }
          return innerQs;
        },
        resource.innerQuestions,
      );
      resourceArr.push(innerQuestions);
      return resourceArr;
    },
    [],
  );
  dict.push({
    ...resourceType,
    resourceTypeResourceArr,
  });
  return dict;
};

const getOrderedQuestions = createSelector(
  getQuestionsOrderedByResource,
  getResources,
  (questions, resources) => {
    let orderedQuestions = reduce(
      questions,
      (dict, e, key) => {
        let resourceArr = [];
        if (resources[e.resource_type_id]) {
          reduce(
            resources[e.resource_type_id],
            (arr, resource) => {
              const resourceVariableName = get(resource, 'resource_variable_name', []);
              const resourceInd = resourceVariableName.findIndex(
                resVarName => resVarName === key,
              );
              if (
                resourceInd !== -1 ||
                (resource.resource_type_id === 2 &&
                  resource.resource_label !== 'Fund Performance')
              ) {
                let updatedQuestions = [...e.innerQuestions];
                reduce(
                  resource.features,
                  (updatedQs, feature) => {
                    const filteredQs = updatedQs.filter(
                      e => e.feature_type.id === feature.feature_type_id,
                    );
                    if (!isEmpty(filteredQs)) {
                      filteredQs.forEach(q => {
                        const questionIndex = updatedQs.findIndex(
                          updatedQ => updatedQ.id === q.id,
                        );
                        let updatedVal = feature.value;
                        if (
                          q.feature_type.name === 'entity_type' &&
                          resource.resource_type === 'Company' &&
                          resource.resource_label
                        ) {
                          const entityNameQIndex = updatedQs.findIndex(
                            updatedQ => updatedQ.feature_type.name === 'name',
                          );
                          if (entityNameQIndex !== -1) {
                            updatedQs[entityNameQIndex] = {
                              ...updatedQs[entityNameQIndex],
                              entityType: feature.value || '',
                            };
                          }
                        }
                        if (
                          q.feature_type.name === 'already_formed_entity' &&
                          resource.resource_type === 'Company'
                        ) {
                          const entityNameQIndex = updatedQs.findIndex(
                            updatedQ => updatedQ.feature_type.name === 'name',
                          );
                          if (entityNameQIndex !== -1) {
                            updatedQs[entityNameQIndex] = {
                              ...updatedQs[entityNameQIndex],
                              isEntityFormed: feature.value === 'Yes',
                            };
                          }
                        }
                        if (q.feature_type.feature_type === 'calendly') {
                          e.isCalendly = true;
                        }
                        if (q.feature_type.feature_type === 'date') {
                          if (feature.value) {
                            updatedVal = moment(feature.value, [
                              'MM-DD-YYYY',
                              'MM/DD/YYYY',
                              'MMM Do, YYYY',
                              'D MMM YYYY',
                              'MMM D, YYYY',
                            ]).format('MM/DD/YYYY');
                          }
                        }
                        if (q.feature_type.feature_type === 'month') {
                          if (feature.value) {
                            updatedVal = moment(updatedVal, [
                              'MM-YYYY',
                              'MM/YYYY',
                              'MMM YYYY',
                            ]).format('MM/YYYY');
                          }
                        }
                        if (q.feature_type.feature_type === 'address') {
                          let matches =
                            updatedVal.match(/(.*)\n(.*)\n(.*), ([a-z\s]*)(.*)/i) || [];
                          if (!isEmpty(updatedVal) && isEmpty(matches)) {
                            updatedVal = defaultAddress;
                          } else {
                            updatedVal = {
                              line_one: get(matches, '[1]', ''),
                              line_two: get(matches, '[2]', ''),
                              city: get(matches, '[3]', ''),
                              state: get(matches, '[4]', ''),
                              zip: get(matches, '[5]', ''),
                            };
                          }
                        }
                        if (q.feature_type.feature_type === 'address_universal') {
                          let matches =
                            updatedVal.match(
                              /(.*)\n(.*)\n(.*), ([a-z\s]*) (.*)?(\n(.*))?/i,
                            ) || [];
                          if (!isEmpty(updatedVal) && isEmpty(matches)) {
                            updatedVal = defaultAddress;
                          } else {
                            updatedVal = {
                              line_one: get(matches, '[1]', ''),
                              line_two: get(matches, '[2]', ''),
                              city: get(matches, '[3]', ''),
                              state: get(matches, '[4]', ''),
                              zip: get(matches, '[5]', ''),
                              country: get(matches, '[7]', 'United States of America'),
                            };
                          }
                        }
                        updatedQs[questionIndex] = {
                          ...updatedQs[questionIndex],
                          value: updatedVal,
                          feature_id: feature.feature_id,
                          fixed: feature.fixed,
                        };
                      });
                    }
                    return updatedQs;
                  },
                  updatedQuestions,
                );
                arr.push({
                  ...e,
                  innerQuestions: updatedQuestions,
                  resource_id: resource.id,
                  resource_name: resource.name,
                  resource_type: resource.resource_type,
                  is_draft: resource.is_draft,
                });
              }
              return arr;
            },
            resourceArr,
          );
        }
        resourceArr.sort((a, b) => a.resource_id - b.resource_id);
        dict.push({
          ...e,
          resourceArr,
        });
        return dict;
      },
      [],
    );
    orderedQuestions.sort((a, b) => {
      if (a.question_order && b.question_order) {
        return a.question_order - b.question_order;
      }
      return a.feature_type.order_by - b.feature_type.order_by;
    });
    orderedQuestions = reduce(orderedQuestions, updatePrereqQuestions, []);
    return orderedQuestions;
  },
);

export const getQuestionnaireResourcesLength = createSelector(
  getOrderedQuestions,
  questions => {
    let length = 0;
    for (let i = 0; i < questions.length; i++) {
      length += questions[i].resourceArr.length;
    }
    return length;
  },
);

const getFeesFromTransaction = createSelector(
  getTransactionById,
  getOrderedQuestions,
  (transaction, questions) => {
    let fees = [];
    let addons = [];
    let feesTotal = 0;
    let addonsTotal = 0;
    let amountTotal = 0;
    let counter = get(transaction, 'tasks', []).length;
    get(transaction, 'tasks', []).forEach((task, i) => {
      const product = get(task, 'product', {});
      let productFees = get(product, 'fees', []);
      let additionalFees = 0;
      for (let fee of productFees) {
        fee.taskId = task.id;
        if (fee.is_annual) {
          const questionIndex = questions.findIndex(e => e.name === 'Company');
          if (questionIndex !== -1) {
            const innerQs = get(
              questions,
              `[${questionIndex}].resourceArr[0].innerQuestions`,
              [],
            );
            const innerQsIndex = innerQs.findIndex(
              e => e.feature_type.id === fee.feature_type_id,
            );
            if (innerQsIndex !== -1) {
              fee.amount =
                innerQs[innerQsIndex].value === 'Delaware'
                  ? '$450'
                  : 'You will be contacted about additional state fees.';
            } else {
              fee.amount = 'You will be contacted about additional state fees';
            }
          }
        }
        if (fee.dynamic_table) {
          const resourceName =
            fee.dynamic_table === 'Foreign LLC' || fee.dynamic_table === 'Foreign Corp'
              ? 'Operating State'
              : 'Company';
          const questionIndex = questions.findIndex(e => e.name === resourceName);
          if (questionIndex !== -1) {
            const innerQs = get(
              questions,
              `[${questionIndex}].resourceArr[0].innerQuestions`,
              [],
            );
            const innerQsIndex = innerQs.findIndex(
              e => e.feature_type.id === fee.feature_type_id,
            );
            if (innerQsIndex !== -1) {
              fee.amount = get(
                LLCDict,
                `[${fee.dynamic_table}][${innerQs[innerQsIndex].value}]`,
                fee.amount,
              );
              additionalFees += fee.amount;
            }
          }
        }
      }
      feesTotal += get(product, 'fee_total', 0) + additionalFees;
      addonsTotal += get(product, 'addon.amount', 0);
      amountTotal +=
        get(product, 'fee_total', 0) + additionalFees + get(product, 'addon.amount', 0);
      fees = [...fees, ...productFees];
      if (product.addon) {
        addons.push({ ...product.addon, taskId: task.id });
      }
      counter--;
    });
    if (counter === 0) {
      return { addons, amountTotal, fees, addonsTotal, feesTotal };
    }
  },
);

export const CustomDocumentsFormikValues = createSelector(CustomDocuments, customDocs => {
  const customDocFormikVals = reduce(
    customDocs,
    (dict, e, index) => {
      if (!isEmpty(e.custom_document)) {
        const updatedFields = Object.entries(e.custom_document.fields).reduce(
          (dict, [fieldKey, fieldValue], ind) => ({
            ...dict,
            [fieldKey]: fieldValue.value,
          }),
          {},
        );
        dict.push({
          fields: updatedFields,
          recipients: e.custom_document.recipients,
        });
      }
      return dict;
    },
    [],
  );
  return customDocFormikVals;
});

const newInvestorNames = [
  'New Applicant',
  'New Investor',
  'New Investor Signatory',
  'New Investor Signatory (Full Legal Name)',
  'New Attorney',
  'New Attorney Signatory',
  'New Captain',
  'New Coach',
  'New Coach Signatory',
  'New Contractor',
  'New Partner',
  'New Partner Signatory',
  'New General Partner',
  'New Prospective Investor',
  'New Fund Manager',
  'New Participant CEO',
  'New Officer',
  'New Potential Founder',
];

const getFormikValues = createSelector(getOrderedQuestions, questions => {
  const formikValues = reduce(
    questions,
    (dict, e, ind) => {
      if (e.resourceArr.length > 0) {
        dict[e.name] = [];
        e.resourceArr.forEach(resource => {
          let resourceName = resource.resource_name;
          if (newInvestorNames.indexOf(resourceName) !== -1) {
            resourceName = '';
          }
          dict[e.name] = [
            ...dict[e.name],
            {
              resource: resourceName,
              ...reduce(
                resource.innerQuestions,
                (obj, val, index) => {
                  if (!val.isHidden) {
                    if (val.feature_type.feature_type === 'boolean') {
                      const valStr = val.value + '';
                      obj[val.question_label] =
                        valStr === '1' || valStr === 'true' ? 'Yes' : 'No';
                    } else if (
                      !val.value &&
                      (val.feature_type.feature_type === 'address' ||
                        val.feature_type.feature_type === 'address_universal')
                    ) {
                      obj[val.question_label] = defaultAddress;
                    } else {
                      obj[val.question_label] = val.value || '';
                    }
                  }
                  return obj;
                },
                {},
              ),
            },
          ];
        });
      } else {
        dict[e.name] = [
          {
            resource: '',
            isInitial: true,
            ...reduce(
              e.innerQuestions,
              (obj, val) => {
                if (
                  val.feature_type.feature_type === 'address' ||
                  val.feature_type.feature_type === 'address_universal'
                ) {
                  obj[val.question_label] = defaultAddress;
                } else {
                  obj[val.question_label] = '';
                }

                return obj;
              },
              {},
            ),
          },
        ];
      }
      return dict;
    },
    {},
  );
  return formikValues;
});

const getReviewValues = createSelector(getOrderedQuestions, questions => {
  const formikValues = reduce(
    questions,
    (dict, e, ind) => {
      if (e.resourceArr.length > 0) {
        dict[e.name] = [];
        e.resourceArr.forEach(resource => {
          dict[e.name] = [
            ...dict[e.name],
            {
              resource: resource.resource_name,
              'Custom Label': resource.custom_label,
              ...reduce(
                resource.innerQuestions,
                (obj, val, index) => {
                  if (
                    !val.isHidden &&
                    val.feature_type.name !== 'name' &&
                    val.feature_type.name !== 'operating_state'
                  ) {
                    if (val.feature_type.feature_type === 'attorney_lane' && val.value) {
                      val.initVal = val.value;
                      val.value =
                        val.lanes[
                          val.lanes.findIndex(
                            lane => lane.id == val.value, // eslint-disable-line eqeqeq
                          )
                        ].title;
                    }
                    if (val.feature_type.feature_type === 'attorney' && val.value) {
                      const lanesQuestionsIndex = resource.innerQuestions.findIndex(
                        innerQ => innerQ.feature_type.feature_type === 'attorney_lane',
                      );
                      if (resource.innerQuestions[lanesQuestionsIndex].initVal) {
                        const attorneysLane =
                          val.attorneys_by_lane[
                            resource.innerQuestions[lanesQuestionsIndex].initVal
                          ];
                        const attorney =
                          attorneysLane[
                            attorneysLane.findIndex(
                              attorney => attorney.id == val.value, // eslint-disable-line eqeqeq
                            )
                          ];
                        val.value = `${attorney.first_name} ${attorney.last_name}`;
                      }
                    }
                    if (val.feature_type.feature_type === 'document') {
                      val.initVal = val.value;
                      if (!!val.value) {
                        obj[val.question_label] = {
                          value: 'Uploaded',
                          isDocument: true,
                          fileId: val.value,
                          label: 'View Document in File Cabinet',
                        };
                      } else {
                        obj[val.question_label] = '';
                      }
                    } else if (
                      val.feature_type.feature_type === 'share_links' &&
                      !!val.value
                    ) {
                      obj[val.question_label] = parseShareLink(val.value);
                    } else if (
                      val.feature_type.feature_type === 'related_resource' &&
                      !!val.value
                    ) {
                      obj[val.question_label] = get(
                        val,
                        `resourceListData[${val.value}][0].value`,
                        val.value,
                      );
                    } else if (
                      val.feature_type.feature_type === 'social_security' &&
                      val.value &&
                      val.value.length === 9
                    ) {
                      obj[val.question_label] = '*****' + val.value.slice(5);
                    } else if (val.feature_type.feature_type === 'template_document') {
                      obj[val.question_label] = {
                        path: val.path,
                        isLink: true,
                        label: 'View Document Template',
                      };
                    } else if (val.feature_type.feature_type === 'boolean') {
                      const valStr = val.value + '';
                      obj[val.question_label] =
                        valStr === 'true' || valStr === '1' ? 'Yes' : 'No';
                    } else {
                      obj[val.question_label] = val.value || '';
                    }
                  }
                  return obj;
                },
                {},
              ),
            },
          ];
        });
      } else {
        dict[e.name] = [
          // {
          //   resource: '',
          //   ...reduce(
          //     e.innerQuestions,
          //     (obj, val) => {
          //       obj[val.question_label] = '';
          //       return obj;
          //     },
          //     {},
          //   ),
          // },
        ];
      }
      return dict;
    },
    {},
  );
  return formikValues;
});

/**  *** MY TASKS VIEW SELECTORS BEGIN *** */
const MyTransactionsCompanies = defaultMemoize(state =>
  get(state, 'Company.companies.byIds', {}),
);

export const MyTransactions = defaultMemoize(state =>
  get(state, 'Transaction.myTransactions', []),
);

export const MyTransactionsWithCompany = createSelector(
  MyTransactions,
  MyTransactionsCompanies,
  (transactions, companies) =>
    transactions.map(e => ({
      ...e,
      account_name: get(companies, `[${e.account_id}].account_name`, ''),
    })),
);
/**  *** MY TASKS VIEW SELECTORS END *** */

// const ACTION_FORM_TEMPLATE_IDS = [413, 388, 355];
const ACTION_FORM_TEMPLATE_IDS = [388, 389, 413, 417, 420, 421, 426, 428, 429, 431, 458];

/**  *** TASKS VIEW SELECTORS BEGIN *** */
export const getAllTransactions = createSelector(
  getTransactionsByModuleId,
  allTransactions => {
    const transactions = allTransactions.reduce((dict, e) => {
      if (ACTION_FORM_TEMPLATE_IDS.includes(e.template.id)) {
        return dict;
      }
      if (e.open) {
        dict.push({
          ...e,
          updatedActionLabel: 'Continue',
          taskStatus: 'Open',
        });
      } else if (e.state === 'Completed') {
        dict.push({
          ...e,
          updatedActionLabel: 'View Task',
          taskStatus: 'Completed',
        });
      } else if (!e.open && (e.template.is_assessment || e.template.id === 88)) {
        dict.push({
          ...e,
          updatedActionLabel: 'View Overview',
          taskStatus: 'Completed',
        });
      } else if (!e.open && e.state === 'Processing') {
        dict.push({
          ...e,
          updatedActionLabel: 'Review',
          taskStatus: 'Pending',
        });
      } else {
        dict.push({
          ...e,
        });
      }
      return dict;
    }, []);
    return transactions;
  },
);

export const getCompletedTransactions = createSelector(
  getTransactionsByModuleId,
  transactions =>
    transactions.reduce((dict, e) => {
      if (e.state === 'Completed') {
        dict.push({
          ...e,
          updatedActionLabel: 'View Task',
          taskStatus: 'Completed',
        });
      } else if (!e.open && (e.template.is_assessment || e.template.id === 88)) {
        dict.push({
          ...e,
          updatedActionLabel: 'View Overview',
          taskStatus: 'Completed',
        });
      }
      // } else if (!e.open && !e.template.is_assessment) {
      //   dict.push({
      //     ...e,
      //     updatedActionLabel: 'Review',
      //     updatedStateLabel: e.state,
      //   });
      // }
      return dict;
    }, []),
);

export const getMissingInfoTransactions = createSelector(
  getTransactionsByModuleId,
  transactions =>
    transactions.reduce((dict, e, ind) => {
      if (e.open) {
        dict.push({
          ...e,
          taskStatus: 'Open',
        });
      } else {
      }
      return dict;
    }, []),
);

export const getPendingTransactions = createSelector(
  getTransactionsByModuleId,
  transactions =>
    transactions.reduce((dict, e, ind) => {
      if (
        !e.open &&
        !e.template.is_assessment &&
        e.template.id !== 88 &&
        e.state !== 'Completed'
        // e.tasks.findIndex(
        //   task =>
        //     (task.template.type === 'Outside' || task.template.type === 'Filing') &&
        //     task.state !== 'completed',
        // ) !== -1
      ) {
        dict.push({
          ...e,
          updatedActionLabel: 'Review',
          taskStatus: 'Pending',
        });
      }
      return dict;
    }, []),
);
/**  *** TASKS VIEW SELECTORS END *** */

export {
  // getReviewData,
  // getValues,
  getFaqsFromTransaction,
  getFeesFromTransaction,
  getFormikValues,
  getOrderedQuestions,
  getOrderedDocumentsByTransaction,
  // getProcessingTransactions,
  getResources,
  getResourceOptions,
  getReviewValues,
  getTransactionById,
  getTransactionsForModuleView,
  getTransactionsByModuleId,
  getTransactionValidators,
};
