import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { Route } from 'react-router-dom';

import { Field, FieldArray, Form } from 'formik';
import { get, isObject, startCase, upperFirst } from 'lodash-es';

// import BasicTopbar from '../../components/BasicTopbar';
import { Button } from '../../components/common';
import FinalizeReviewModal from '../../components/FinalizeReviewModal';
import { CompanyName, ResourceSelect } from '../../components/formik';
import FeatureTypes from '../../utils/FeatureTypes';
import SavviLoading from '../../components/SavviLoading';

const ReviewInfo = reviewObj =>
  Object.entries(reviewObj).map(([resourceKey, resourceArr], index) => (
    <div className="finalize-section__header" key={`review-${resourceKey}-${index}`}>
      {resourceArr.map((resource, resourceInd) => (
        <Fragment key={`resource-array-${resourceInd}`}>
          {resource.resource && (
            <h4>
              <em>{`${resource.resource} (${resource['Custom Label']})`}</em>
            </h4>
          )}
          {resource.resource && (
            <div className="finalize-section__row">
              {Object.entries(resource).map(([key, value], ind) => {
                if (
                  key !== 'resource' &&
                  key !== 'Company Name' &&
                  key !== 'Name' &&
                  key !== 'Custom Label'
                ) {
                  let updatedVal = value;

                  if (isObject(value)) {
                    if (value.isLink) {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>
                            {key}: <a href={value.path}>{value.label}</a>
                          </h4>
                        </Fragment>
                      );
                    }
                    if (!Object.values(value).join('')) {
                      updatedVal = 'None Given';
                    } else {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>{key}:</h4>
                          <p>{value.line_one}</p>
                          {value.line_two.length > 0 && <p>{value.line_two}</p>}
                          <p>{`${startCase(value.city)}, ${value.state} ${value.zip}`}</p>
                        </Fragment>
                      );
                    }
                  } else if (value.indexOf('|') !== -1) {
                    updatedVal = updatedVal.split('|').join(', ');
                  }
                  return (
                    <h4 key={`review-info-${key}-${ind}`}>
                      {key}: <p>{updatedVal || 'Not Given'}</p>
                    </h4>
                  );
                }
                return null;
              })}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  ));

const OutsideForm = ({
  error,
  formValidationErrors,
  handleDelete,
  handleFinalize,
  handleFormBlur,
  handleResourceName,
  // handleSetResourceIds,
  history,
  isFetching,
  isFetchingInit,
  isFinalized,
  isReviewModalOpen,
  // values = {},
  params,
  questionnaireMeta,
  questions,
  reviewInfo,
  toggleReviewModal,
  ...props
}) => {
  return (
    <div className="outside-form__content">
      <div className={`outside-form-header${error ? ' outside-form-header--error' : ''}`}>
        <h1>{questionnaireMeta.name || 'Outside Form'}</h1>
        <h4>{questionnaireMeta.description}</h4>
        <h4>
          Questions marked with <span>*</span> are required.
        </h4>
      </div>
      {isFinalized && (
        <div className="outside-form__loader">
          Thank you, this form has been completed.
        </div>
      )}
      {!isFetchingInit && error && <div className="outside-form__loader">{error}</div>}
      {isFetchingInit && (
        <div className="outside-form__loader">
          <SavviLoading />
          <span className="workbench__loading">Loading...</span>
        </div>
      )}
      {!isFinalized && !error && questions.length > 0 && (
        <Form className="workbench-form" id="workbench__form" autoComplete="nope">
          {!!formValidationErrors.length > 0 && (
            <div className="workbench-form__errors">
              {formValidationErrors.map((err, ind) => (
                <p key={`form-validation-error-${ind}`}>{err}</p>
              ))}
            </div>
          )}
          {questions.map(question => {
            const {
              custom_label,
              innerQuestions = [],
              isCompany,
              multi_entry, // property to tell if you have multiple of that resource.
              name,
              resourceArr = [],
              resource_type_id,
              resource_label,
            } = question;
            let updatedLabel = custom_label;
            if (!(custom_label || '').includes('Name')) {
              updatedLabel = updatedLabel + ' Name';
            }
            let operatingStateInd = innerQuestions.findIndex(
              e => e.feature_type.name === 'operating_state',
            );
            let companyName = get(resourceArr, '[0].resource_name', 'Company');
            if (operatingStateInd !== -1) {
              updatedLabel = innerQuestions[operatingStateInd].question_text;
            }

            return (
              <FieldArray
                key={`multi-resource-${name}`}
                name={name}
                render={arrayHelpers => (
                  <div className="workbench-form__group">
                    <h3 className="form__heading">
                      {(resource_type_id !== 2 ||
                        (resource_type_id === 2 &&
                          resource_label === 'Fund Performance')) &&
                        `${upperFirst(custom_label)} Information`}
                      {resource_type_id === 2 &&
                        resource_label !== 'Fund Performance' &&
                        `${companyName} Information*`}

                      {(resource_type_id !== 2 ||
                        (resource_type_id === 2 &&
                          resource_label === 'Fund Performance')) &&
                        resource_type_id !== 16 &&
                        resource_type_id !== 25 &&
                        !!multi_entry && (
                          <small className="form__sub-heading">
                            Enter the names of each {custom_label}:
                          </small>
                        )}
                    </h3>
                    {resourceArr.length > 0 &&
                      resourceArr[0].resource_name &&
                      resourceArr.map((resource, index) => (
                        <Fragment key={`resource-${resource.resource_name}`}>
                          {resource_type_id !== 6 &&
                            (resource_type_id !== 2 ||
                              (resource_type_id === 2 &&
                                resource_label === 'Fund Performance')) && (
                              <Field
                                autoComplete="nope"
                                component={isCompany ? CompanyName : ResourceSelect}
                                isDraftResource={resource.is_draft}
                                isEntityFormed={get(
                                  resource,
                                  'innerQuestions[0].isEntityFormed',
                                  false,
                                )}
                                isOutsideForm
                                entityType={get(
                                  resource,
                                  'innerQuestions[0].entityType',
                                  '',
                                )}
                                handleChangeResource={() => {}}
                                handleDelete={handleDelete}
                                handleRemoveResource={() => {}}
                                handleResourceName={name =>
                                  handleResourceName(
                                    {
                                      name,
                                    },
                                    resource.resource_id,
                                  )
                                }
                                icon="user"
                                isCompany={isCompany}
                                isFixed={resource.innerQuestions[0].fixed}
                                isRequired
                                questionHelp={
                                  resource.innerQuestions[0].question_help ||
                                  resource.innerQuestions[0].feature_type.question_help
                                }
                                sublabel={
                                  resource.innerQuestions[0].question_comment ||
                                  resource.innerQuestions[0].feature_type.question_comment
                                }
                                isWorkbench
                                label={updatedLabel}
                                name={`${name}[${index}].resource`}
                                onResourceSelect={() => {}}
                                params={params}
                                resourceTypeName={name}
                                resourceTypeCustomLabel={custom_label}
                                setLabel={() => {}}
                              />
                            )}
                          {resource.innerQuestions.map((innerQ, questionIndex) => {
                            if (
                              innerQ.isHidden ||
                              innerQ.feature_type.name === 'operating_state' ||
                              innerQ.feature_type.name === 'name'
                            ) {
                              return null;
                            }
                            return (
                              <Fragment
                                key={`inner-questions-${resource.resource_name}-${innerQ.name}`}
                              >
                                <Question
                                  handleFormBlur={e => {
                                    if (e.target.value !== innerQ.value) {
                                      handleFormBlur(
                                        e.target.value,
                                        innerQ.feature_type.id,
                                        innerQ.feature_id,
                                        resource.resource_id,
                                        resource_type_id,
                                      );
                                    }
                                  }}
                                  featureProps={innerQ}
                                  innerQuestions={resource.innerQuestions}
                                  params={params}
                                  resourceTypeName={name}
                                  resourceIndex={index}
                                  resource_id={resource.resource_id}
                                  resource_name={resource.resource_name}
                                />
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      ))}
                  </div>
                )}
              />
            );
          })}
          {!!formValidationErrors.length > 0 && (
            <div className="workbench-form__errors">
              {formValidationErrors.map((err, ind) => (
                <p key={`form-validation-error-${ind}`}>{err}</p>
              ))}
            </div>
          )}
          <div className="outside-form__bottom">
            <div className="outside-form-buttons">
              <Button
                isFetching={isFetching || isFinalized}
                isDisabled={isFinalized}
                size="wide"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      )}
      <div className="outside-form__help">
        <p>
          For help with any issues please contact us at{' '}
          <a href="mailto:support@savvi.legal">support@savvi.legal</a>
        </p>
      </div>
      {isReviewModalOpen && (
        <FinalizeReviewModal
          history={history}
          isFetching={isFetching}
          isOutsideForm
          isOpen={isReviewModalOpen}
          handleFinalize={handleFinalize}
          onClose={() => toggleReviewModal()}
          params={params}
          reviewInfo={reviewInfo}
          ReviewInfoComponent={ReviewInfo}
        />
      )}
    </div>
  );
};

const Question = ({
  featureProps,
  handleFormBlur,
  innerQuestions,
  params,
  resourceTypeName,
  resourceIndex,
  resource_id,
  resource_name,
}) => {
  const {
    feature_type: {
      calendly_options,
      feature_type,
      filename,
      is_creatable,
      multi_entry_feature,
      json: { markdown = '' } = {},
      options,
      path: qpath,
      question_comment: qc,
      question_help: qh,
    } = {},
    feature_type_id,
    fixed,
    json: { markdown: flattenedMarkdown = '' } = {},
    is_label,
    is_required,
    path,
    question_comment,
    question_help,
    question_label,
    question_placeholder,
    question_text,
    tags,
  } = featureProps;
  let updatedOptions = calendly_options || options;
  if (feature_type === 'detailed_options') {
    updatedOptions = JSON.parse(options);
  }
  let isRequired = !!is_required;

  if (feature_type === 'social_security') {
    const addressIndex = innerQuestions.findIndex(innerQ =>
      innerQ.feature_type.feature_type.includes('address'),
    );

    if (addressIndex !== -1) {
      const isAddressUSA = (
        innerQuestions[addressIndex]?.value?.country?.value ||
        innerQuestions[addressIndex]?.value?.country ||
        ''
      ).includes('United States of America');
      if (isAddressUSA) {
        isRequired = true;
      } else {
        isRequired = false;
      }
    }
  }
  const updatedName = `${resourceTypeName}[${resourceIndex}]${question_label}`;

  return (
    <div className="grid--flex">
      <Field
        autoComplete="nope"
        component={FeatureTypes[feature_type].Formik}
        featureProps={featureProps}
        feature_type={feature_type}
        feature_type_id={feature_type_id}
        filename={filename}
        handleFormBlur={handleFormBlur}
        isCreatable={!!is_creatable}
        isDetailedOptions={feature_type === 'detailed_options'}
        isFixed={!!fixed}
        isLabel={!!is_label}
        isOutsideForm
        isRequired={isRequired}
        label={question_text}
        markdown={markdown || flattenedMarkdown}
        multiEntry={multi_entry_feature}
        name={updatedName}
        options={updatedOptions}
        params={params}
        path={path || qpath}
        placeholder={question_placeholder}
        questionHelp={question_help || qh}
        resource_id={resource_id}
        resource_name={resource_name}
        resetValue={FeatureTypes[feature_type].value}
        setLabel={() => {}}
        sublabel={question_comment || qc}
        tags={tags}
        taskId={params.transactionId}
        validate={isRequired && FeatureTypes[feature_type].validate}
      />
    </div>
  );
};

OutsideForm.propTypes = {
  isFetching: PropTypes.bool,
  formTitle: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  values: PropTypes.object,
};

OutsideForm.defaultProps = {
  status: {},
};

export default OutsideForm;
